import { create } from '@storybook/theming/create';
import colors from '@latticehr/colors';

export default create({
  // Brand
  brandTitle: 'Lattice Storybook',
  brandImage: 'logo.svg',

  // UI
  base: 'light',
  appBorderColor: colors.border,
  appBorderRadius: 6,
  colorPrimary: colors.primary,
  colorSecondary: colors.secondary,

  // Text
  fontBase:
    'Inter, -apple-system, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
  textColor: colors.text.primary,
  textInverseColor: colors.white,
  textMutedColor: colors.text.secondary,

  // Toolbar
  barTextColor: colors.text.primary,
  barSelectedColor: colors.blue[200],
  barHoverColor: colors.neutral[300],

  // Input
  inputBorder: colors.border,
  inputTextColor: colors.text.primary,
  inputBorderRadius: 6,
});
