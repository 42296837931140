import { addons } from '@storybook/manager-api';
import { themes } from '@storybook/theming';
import theme from './theme';

import favicon from '../public/favicon.svg';
import './global.css';

// Add a custom favicon, why not?
const link = document.createElement('link');
link.setAttribute('rel', 'shortcut icon');
link.setAttribute('href', favicon);
document.head.appendChild(link);

addons.setConfig({
  theme: theme,
});
